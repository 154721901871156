import {BoardingLayout} from "@ef-org/layouts";
import {LoginPage} from "@ef-org/scenes";

import {NextPageWithLayout} from "./_app";

const Index: NextPageWithLayout = () => <LoginPage />;

Index.getLayout = (page) => {
  return (
    <BoardingLayout
      metaTags={{title: "Login"}}
      displayLegacyLoginAlert
      rightSection={{
        imageUrl: "/images/onboarding/wallet.png",
        heading: "Monetize smarter",
        texting: "Sell digital assets with less overhead cost and build robust communities.",
      }}
    >
      {page}
    </BoardingLayout>
  );
};

export default Index;
